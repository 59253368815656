@import "./palette.scss";
@import "./units.scss";

/* App Header */

.appHeader {
  text-align: center;
  background-color: $blue;
  min-height: 15vmin;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.collapsible {
  margin-bottom: $m;
  transition: max-height 0.5s ease-in-out;
  overflow: hidden;
  max-height: 100px;
}

.collapsed {
  max-height: 0;
}

/* Main content */

.appMain {
  max-width: 1024px;
  margin: 30px auto;
}

.feedTitle {
  color: $baby;
}

/* Entries */

.entryDate {
  display: flex;
  font-weight: 500;
  margin-bottom: $s;
}

.entryContent {
  /* GitHub Theming */
  :global {
    @import "@primer/css/core/index.scss";
    @import "@primer/css/product/index.scss";
    @import "~github-syntax-light/lib/github-light.css";

    .repo-language-color {
      border-radius: 50%;
      display: inline-block;
      height: 12px;
      position: relative;
      top: 1px;
      width: 12px;
    }
  }
}

.loader,
.loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}

$cream_op: rgba(248, 255, 244, 0.2);

.loader {
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid $cream_op;
  border-right: 1.1em solid $cream_op;
  border-bottom: 1.1em solid $cream_op;
  border-left: 1.1em solid $gray;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
