/* Coolors Exported Palette - coolors.co/26547c-bac1b8-f8fff4-ed6a5a-f4f1bb */

/* HSL */
$blue: hsla(208%, 53%, 32%, 1);
$gray: hsla(107%, 7%, 74%, 1);
$cream: hsla(98%, 100%, 98%, 1);
$red: hsla(7%, 80%, 64%, 1);
$blond: hsla(57%, 72%, 85%, 1);
$baby: hsla(60%, 100%, 99%, 1);

/* RGB */
$blue: rgba(38, 84, 124, 1);
$gray: rgba(186, 193, 184, 1);
$cream: rgba(248, 255, 244, 1);
$red: rgba(237, 106, 90, 1);
$blond: rgba(244, 241, 187, 1);
$baby: rgba(255, 255, 250, 1);
