@import "./palette.scss";
@import "./units.scss";

.card {
  position: relative;
  display: flex;
  background-color: $baby;
  border-radius: 8px;
  box-shadow: 0 $xs $xs 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  margin-bottom: $s;
  transition: all 0.3s;
}

.card::after {
  content: " ";
  position: absolute;
  border-radius: 8px;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 $xs $s 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  opacity: 0;
  transition: opacity 0.3s;
}

.card:hover {
  margin-bottom: $m;
  transform: scale(1.01);
}

.card:hover::after {
  opacity: 1;
}

.cardInner {
  margin: 20px;
}
